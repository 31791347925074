import { Card } from 'antd';

import styles from './ResetPasswordNotice.module.css';

function ResetPasswordNotice() {
  const managers = [
    {
      index: 1,
      team: '한국마케팅플랫폼팀',
      name: '강호찬',
      rank: '사원',
      email_addr: 'hochan.kang@lgcns.com',
    },
  ];

  return (
    <Card className={styles.container}>
      <ul className={styles.content}>
        <li>비밀번호 초기화는 다음 담당자에게 문의하시기 바랍니다.</li>
        <ul className={styles['no-bullet']}>
          {managers.map(({ index, team, name, rank, email_addr }) => (
            <li key={index}>
              {`${team} ${name} ${rank}`}(<a href={`mailto:${email_addr}`}>{email_addr}</a>)
            </li>
          ))}
        </ul>
      </ul>
    </Card>
  );
}

export default ResetPasswordNotice;
