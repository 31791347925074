import { useSearchParams } from 'react-router-dom';

import Center from 'layout/Center';
import FullScreen from 'layout/FullScreen';

import InqueryInfo from './component/InqueryInfo';
import LoginForm from './component/LoginForm';

import styles from './LoginPage.module.css';

function LoginPage() {
  const [searchParams] = useSearchParams();
  const redirectURI = decodeURIComponent(searchParams.get('redirectURI') ?? '');

  const onLoginSuccess = () => {
    const home: string = process.env.REACT_APP_ORIGIN ?? '';
    window.location.assign(home + redirectURI);
  };

  return (
    <FullScreen>
      <Center>
        <div className={styles.content}>
          <LoginForm onLoginSuccess={onLoginSuccess} />
          <InqueryInfo />
        </div>
      </Center>
    </FullScreen>
  );
}

export default LoginPage;
