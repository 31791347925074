import { memo, useState } from 'react';
import { Alert, Button, Card, Form, Input } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import login from 'api/login';

import styles from './LoginForm.module.css';

const USER_ID_FIELD = 'userId';
const PASSWORD_FIELD = 'password';

interface LoginFormProps {
  onLoginSuccess: () => void;
}

interface LoginFormField {
  [USER_ID_FIELD]: string;
  [PASSWORD_FIELD]: string;
}

function LoginForm({ onLoginSuccess }: LoginFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const onFinish = ({ userId, password }: LoginFormField) => {
    if (isLoading) return;

    setIsLoading(true);
    login(userId, password)
      .then(onLoginSuccess)
      .catch((err: Error) => {
        setErrMsg(err.message);
      })
      .finally(() => setIsLoading(false));
  };

  const resetErrMsg = () => setErrMsg('');

  return (
    <Card>
      <Form onFinish={onFinish} onFieldsChange={resetErrMsg}>
        <Form.Item name={USER_ID_FIELD} rules={[{ required: true, message: 'ID를 입력해주세요' }]}>
          <Input prefix={<UserOutlined className={styles['prefix-icon']} />} placeholder='Account' autoFocus />
        </Form.Item>
        <Form.Item
          name={PASSWORD_FIELD}
          rules={[
            {
              required: true,
              message: '패스워드를 입력해주세요',
            },
          ]}>
          <Input.Password prefix={<LockOutlined className={styles['prefix-icon']} />} placeholder='Password' />
        </Form.Item>
        {errMsg && (
          <Form.Item>
            <Alert message={errMsg} type='error' />
          </Form.Item>
        )}
        <Form.Item noStyle>
          <Button type='primary' htmlType='submit' className={styles['login-button']} loading={isLoading}>
            Log In
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default memo(LoginForm);
