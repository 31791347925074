import { Navigate, RouteObject } from 'react-router-dom';

import LoginPage from 'page/login/LoginPage';
import NotFound from 'page/error/NotFound';
import ChangePasswordPage from 'page/password/ChangePasswordPage';

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to='/login' />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/password',
    element: <ChangePasswordPage />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

export default routes;
