import api from './api';

const changePassword = async (oldPassword: string, newPassword: string): Promise<string> => {
  try {
    return await api.post('/api/auth/password', {
      oldPassword,
      newPassword,
    });
  } catch (err: any) {
    let errMsg = '알 수 없는 이유로 비밀번호 변경에 실패하였습니다.';

    if (err.response) {
      const { status } = err.response;
      if (status === 400) errMsg = '입력 값이 올바르지 않습니다.';
      if (status === 401) errMsg = '현재 비밀번호가 올바르지 않습니다.';
    }

    return Promise.reject(new Error(errMsg));
  }
};

export default changePassword;
