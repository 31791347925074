import { memo, useState } from 'react';
import { Alert, Button, Card, Form, Input, Popover, Progress, Typography } from 'antd';

import changePassword from 'api/changePassword';

import styles from './ChangePasswordForm.module.css';

const { Text } = Typography;

const ID_FIELD = 'id';
const OLD_PASSWORD_FIELD = 'oldPassword';
const NEW_PASSWORD_FIELD = 'newPassword';
const NEW_PASSWORD_CONFIRM_FIELD = 'newPasswordConfirm';

interface ChangePasswordFormProps {
  targetUserId: string;
  onChangePasswordSuccess: () => void;
}

interface ChangePasswordFormField {
  [OLD_PASSWORD_FIELD]: string;
  [NEW_PASSWORD_FIELD]: string;
  [NEW_PASSWORD_CONFIRM_FIELD]: string;
}

function ChangePasswordForm({ targetUserId, onChangePasswordSuccess }: ChangePasswordFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [openPasswordRulePopover, setOpenPasswordRulePopover] = useState<boolean>(false);
  const [fulfilledPasswordRule, setFulfilledPasswordRule] = useState<boolean[]>([false, false, false, false]);

  const passwordRule = [
    '영문자를 포함해야 합니다',
    '숫자를 포함해야 합니다',
    '특수문자를 포함해야 합니다',
    '길이는 8 ~ 20자여야 합니다',
  ];

  const validatePassword = (password: string) => {
    const newFulfilledPasswordRule = [false, false, false, false];
    if (/[a-zA-Z]/.test(password)) newFulfilledPasswordRule[0] = true;
    if (/\d/.test(password)) newFulfilledPasswordRule[1] = true;
    if (/[^a-zA-Z\d\s]/.test(password)) newFulfilledPasswordRule[2] = true;
    if (password.length >= 8 && password.length <= 20) newFulfilledPasswordRule[3] = true;
    setFulfilledPasswordRule(newFulfilledPasswordRule);
  };

  const getFulfilledPasswordRuleCount = () => fulfilledPasswordRule.filter(isFulfilled => isFulfilled).length;

  let progressColor = '#f5222d';
  switch (getFulfilledPasswordRuleCount()) {
    case 2:
    case 3:
      progressColor = '#faad14';
      break;
    case 4:
      progressColor = '#389e0d';
      break;
    default:
      progressColor = '#f5222d';
  }

  const onFinish = ({ oldPassword, newPassword }: ChangePasswordFormField) => {
    if (isLoading) return;

    if (getFulfilledPasswordRuleCount() !== passwordRule.length) {
      setErrMsg('비밀번호 규칙을 모두 만족시켜주세요.');
      return;
    }

    setIsLoading(true);
    changePassword(oldPassword, newPassword)
      .then(onChangePasswordSuccess)
      .catch((err: Error) => {
        setErrMsg(err.message);
      })
      .finally(() => setIsLoading(false));
  };

  const resetErrMsg = () => setErrMsg('');

  const handleFieldsChange = (changedValue: any) => {
    resetErrMsg();
    const newPassword = changedValue[NEW_PASSWORD_FIELD];
    if (newPassword !== undefined) {
      validatePassword(newPassword);
    }
  };

  return (
    <Card>
      <Form layout='vertical' onFinish={onFinish} onValuesChange={handleFieldsChange}>
        <Form.Item label='아이디' name={ID_FIELD} initialValue={targetUserId}>
          <Input readOnly />
        </Form.Item>
        <Form.Item
          label='현재 비밀번호'
          name={OLD_PASSWORD_FIELD}
          rules={[
            {
              required: true,
              message: '값을 입력해주세요',
            },
          ]}>
          <Input.Password placeholder='현재 비밀번호를 입력해주세요' />
        </Form.Item>
        <Form.Item
          label={
            <Popover
              content={
                <>
                  <Progress
                    steps={passwordRule.length}
                    percent={fulfilledPasswordRule.filter(isFulfilled => isFulfilled).length * 25}
                    strokeColor={progressColor}
                    size={[50, 10]}
                    showInfo={false}
                  />
                  <ul className={styles.rules}>
                    {passwordRule.map((rule, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={index}>
                        <Text type={fulfilledPasswordRule[index] ? 'success' : 'danger'}>{`${rule}${
                          fulfilledPasswordRule[index] ? ' ✓' : ''
                        }`}</Text>
                      </li>
                    ))}
                  </ul>
                </>
              }
              open={openPasswordRulePopover}
              placement='topLeft'>
              새 비밀번호
            </Popover>
          }
          name={NEW_PASSWORD_FIELD}
          rules={[
            {
              required: true,
              message: '값을 입력해주세요',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue(OLD_PASSWORD_FIELD) !== value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('현재 비밀번호와 달라야 합니다.'));
              },
            }),
          ]}>
          <Input.Password
            placeholder='새 비밀번호를 입력해주세요'
            onFocus={() => setOpenPasswordRulePopover(true)}
            onBlur={() => setOpenPasswordRulePopover(false)}
          />
        </Form.Item>

        <Form.Item
          label='비밀번호 확인'
          name={NEW_PASSWORD_CONFIRM_FIELD}
          dependencies={[NEW_PASSWORD_FIELD]}
          rules={[
            {
              required: true,
              message: '값을 입력해주세요',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue(NEW_PASSWORD_FIELD) === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('새 비밀번호가 일치하지 않습니다.'));
              },
            }),
          ]}>
          <Input.Password placeholder='새 비밀번호를 다시 입력해주세요' />
        </Form.Item>
        {errMsg && (
          <Form.Item>
            <Alert message={errMsg} type='error' />
          </Form.Item>
        )}
        <Form.Item noStyle>
          <Button type='primary' htmlType='submit' className={styles['submit-button']} loading={isLoading}>
            비밀번호 변경
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default memo(ChangePasswordForm);
