import { Card } from 'antd';

import styles from './InqueryInfo.module.css';

function InqueryInfo() {
  const userManagers = [
    {
      index: 1,
      team: '한국마케팅플랫폼팀',
      name: '강호찬',
      rank: '사원',
      email_addr: 'hochan.kang@lgcns.com',
    },
  ];

  // const spbManagers = [
  //   {
  //     index: 1,
  //     team: '한국마케팅플랫폼팀',
  //     name: '유우빈',
  //     rank: '사원',
  //     email_addr: 'milk@lgcns.com',
  //   },
  //   {
  //     index: 2,
  //     team: '한국마케팅플랫폼팀',
  //     name: '손승연',
  //     rank: '사원',
  //     email_addr: 'seungyeon@lgcns.com',
  //   },
  // ];

  // const tracerManagers = [
  //   {
  //     index: 1,
  //     team: '한국마케팅플랫폼팀',
  //     name: '강호찬',
  //     rank: '사원',
  //     email_addr: 'hochan.kang@lgcns.com',
  //   },
  // ];

  return (
    <Card className={styles.container}>
      <ul className={styles.content}>
        <li>계정 및 권한 관련 내용은 다음 담당자에게 문의하시기 바랍니다.</li>
        <ul className={styles['no-bullet']}>
          {userManagers.map(({ index, team, name, rank, email_addr }) => (
            <li key={index}>
              {`${team} ${name} ${rank}`}(<a href={`mailto:${email_addr}`}>{email_addr}</a>)
            </li>
          ))}
        </ul>
        {/* <li>시스템 관련 내용은 다음 담당자에게 문의하시기 바랍니다.</li>
        <ul className={styles.inner}>
          <li>속도 측정 대시보드 (SPB)</li>
          <ul className={styles['no-bullet']}>
            {spbManagers.map(({ index, team, name, rank, email_addr }) => (
              <li key={index}>
                {`${team} ${name} ${rank}`}(<a href={`mailto:${email_addr}`}>{email_addr}</a>)
              </li>
            ))}
          </ul>
          <li>업무현황판 (TRACER)</li>
          <ul className={styles['no-bullet']}>
            {tracerManagers.map(({ index, team, name, rank, email_addr }) => (
              <li key={index}>
                {`${team} ${name} ${rank}`}(<a href={`mailto:${email_addr}`}>{email_addr}</a>)
              </li>
            ))}
          </ul>
        </ul> */}
      </ul>
    </Card>
  );
}

export default InqueryInfo;
