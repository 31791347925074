import api from './api';

const login = async (userId: string, password: string): Promise<string> => {
  try {
    return await api.post('/api/auth/login', {
      userId,
      password,
    });
  } catch (err: any) {
    let errMsg = '알 수 없는 이유로 로그인에 실패하였습니다.';

    if (err.response) {
      const { status } = err.response;
      if (status === 400 || status === 401) errMsg = '아이디 또는 비밀번호가 올바르지 않습니다.';
      if (status === 403) errMsg = '권한이 생성되지 않았습니다. 관리자에게 문의해주세요.';
    }

    return Promise.reject(new Error(errMsg));
  }
};

interface LoginUser {
  userId: string;
}

export const getLoginUser = async (): Promise<string> => {
  const resp = await api.get('/api/auth/login');
  return (resp.data as LoginUser).userId;
};

export default login;
