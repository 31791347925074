import axios from 'axios';

const baseURL = process.env.REACT_APP_ORIGIN;

const api = axios.create({
  baseURL,
  withCredentials: true,
  timeout: 2500,
});

export default api;
