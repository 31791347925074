import { memo, ReactNode } from 'react';
import styles from './FullScreen.module.css';

interface FullScreenProps {
  children: ReactNode;
  backgroundColor?: string;
}

function FullScreen({ children, backgroundColor }: FullScreenProps) {
  return (
    <div className={styles['full-screen']} style={{ backgroundColor }}>
      {children}
    </div>
  );
}

FullScreen.defaultProps = {
  backgroundColor: 'whitesmoke',
};

export default memo(FullScreen);
