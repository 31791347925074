import { memo, ReactNode } from 'react';

import styles from './Center.module.css';

interface CenterProps {
  children: ReactNode;
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
}

function Center({ children, flexDirection }: CenterProps) {
  return (
    <div className={styles.center} style={{ flexDirection }}>
      {children}
    </div>
  );
}

Center.defaultProps = {
  flexDirection: 'column',
};

export default memo(Center);
