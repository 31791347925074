import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { message, Spin, Typography } from 'antd';

import { getLoginUser } from 'api/login';

import Center from 'layout/Center';
import FullScreen from 'layout/FullScreen';
import ChangePasswordForm from './component/ChangePasswordForm';

import styles from './ChangePasswordPage.module.css';
import ResetPasswordNotice from './component/ResetPasswordNotice';

const { Title } = Typography;

function ChangePasswordPage() {
  const navigate = useNavigate();
  const loginPageURL = `/login${window.location.search}`;

  const [targetUserId, setTargetUserId] = useState<string>('');
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  useEffect(() => {
    if (initialLoading) {
      getLoginUser()
        .then(loginUserId => {
          setTargetUserId(loginUserId);
          setInitialLoading(false);
        })
        .catch(() => {
          message.warning('로그인이 필요한 페이지입니다.');
          navigate(loginPageURL, { replace: true });
        });
    }
  }, [initialLoading, loginPageURL, navigate]);

  const handleChangePassworSuccess = () => {
    message.success('패스워드가 변경되었습니다. 다시 로그인 해주세요.');
    navigate(loginPageURL);
  };

  return (
    <FullScreen>
      <Center>
        {initialLoading ? (
          <Spin />
        ) : (
          <div className={styles.content}>
            <Title className={styles.title}>비밀번호 변경</Title>
            <ChangePasswordForm targetUserId={targetUserId} onChangePasswordSuccess={handleChangePassworSuccess} />
            <ResetPasswordNotice />
          </div>
        )}
      </Center>
    </FullScreen>
  );
}

export default ChangePasswordPage;
